import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ProductCatalogService {


    paginate(rootID,index = null) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(rootID,id) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children';
        return apiService.get(url);
    }
    getCatlogByRootId(rootID){
        let url = API_URL + 'user/ecommerce/root/'+rootID+'/catalog-list';
        return apiService.get(url);
    }
    create(rootID,data) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children';
        return apiService.post(url, data);
    }

    update(rootID, id, data) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children/'+id+'/update';
        return apiService.post(url, data);
    }

    delete(rootID, id) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children-delete';
        return apiService.post(url, id);
    }
    enableCatalog(rootID, id) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children-enable';
        return apiService.post(url, id);
    }

    disableCatalog(rootID, id) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+rootID+'/children-disable';
        return apiService.post(url, id);
    }

    show(id) {
        let url = API_URL + 'user/ecommerce/root-catalog/' + id
        return apiService.get(url);
    }


}
