<template>
    <!-- end:: Header -->
    <v-app>
        <h3>Shortcode Generator</h3>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-select
                                :items="['blog', 'product','form', 'contact']"
                                @change="resetDataTypes"
                                dense
                                label="Shortcode Type"
                                outlined
                                v-model="type"
                        ></v-select>
                    </v-col>
                    <!-- <pre>{{type}}</pre> -->

                    <v-col cols="6" v-if="type && type == 'blog'">
                        <v-select
                                :items="blog_types"
                                dense
                                item-text="title"
                                item-value="value"
                                label="Blog Type"
                                outlined
                                v-model="blog_type"
                        ></v-select>
                    </v-col>

                    <v-col cols="6" v-if="type && type == 'product'">
                        <v-select
                                :items="product_types"
                                dense
                                item-text="title"
                                item-value="value"
                                label="Product Type"
                                outlined
                                v-model="product_type"
                        ></v-select>
                    </v-col>
                    <v-col
                            cols="6"
                            v-if="
                            product_type == 'product_category'
                        || blog_type =='blog_with_category'
                        || type =='form'"
                    >
                        <v-select
                                :items="websites"
                                @change="performAction()"
                                dense
                                item-text="title"
                                item-value="title"
                                label="Website"
                                outlined
                                v-model="domain_name"
                        ></v-select>
                    </v-col>
                    <v-col  cols="6" domain_name v-if="type && type == 'form'">
                        <v-select
                                :items="customForms"
                                dense
                                item-text="title"
                                item-value="id"
                                label="Form"
                                outlined
                                v-model="form_id"
                        ></v-select>
                    </v-col>
                    <v-col
                            cols="6"
                            v-if="product_type && product_type == 'product_category'"
                    >
                        <treeselect
                                :disable-branch-nodes="false"
                                :multiple="true"
                                :options="catalogs"
                                :sort-value-by="sortValueBy"
                                :value-consists-of="valueConsistsOf"
                                placeholder="Choose catalogs"
                                v-model="product_catalog_id"
                        />
                        <!-- <v-select
                          :items="['cataglo1', 'cataglo2', 'cataglo3']"
                          outlined
                          dense
                          v-model="product_catalog_id"
                          label="Product Catalog"
                        ></v-select>-->
                    </v-col>
                    <v-col
                            cols="6"
                            v-if="blog_type && blog_type == 'blog_with_category'"
                    >
                        <treeselect
                                :disable-branch-nodes="false"
                                :multiple="true"
                                :options="categories"
                                :sort-value-by="sortValueBy"
                                :value-consists-of="valueConsistsOf"
                                placeholder="Choose Blog Category"
                                v-model="category_ids"
                        />
                        <!-- <v-select
                          :items="['cataglo1', 'cataglo2', 'cataglo3']"
                          outlined
                          dense
                          v-model="product_catalog_id"
                          label="Product Catalog"
                        ></v-select>-->
                    </v-col>
                    <v-col cols="6" v-if="type && type != 'contact' && type != 'form'">
                        <v-select
                                :items="limits"
                                dense
                                label="Limit"
                                outlined
                                v-model="limit"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="type">
                    <v-col cols="12">
                        <p>Paste below code to render shortcode on view</p>
                        <span class="display-1" v-if="type == 'contact'">[contact_form]</span>
                        <div v-else-if="type =='blog' && blog_type">
                                    <span
                                            class="display-1"
                                            v-if="blog_type =='blog_recently_added'"
                                    >[{{blog_type}} limit={{limit}}]</span>
                            <span
                                    class="display-1"
                                    v-else
                            >[{{blog_type}} id={{selected_categories}} limit={{limit}}]</span>
                        </div>  <div v-else-if="type =='form' ">

                            <span
                                    class="display-1"
                            >[form_builder id={{form_id}} ]</span>
                        </div>
                        <div v-else>
                                    <span
                                            class="display-1"
                                            v-if="product_type && product_type != 'product_category'"
                                    >[{{product_type}} limit={{limit}}]</span>

                            <span
                                    class="display-1"
                                    v-else-if="product_type == 'product_category'"
                            >[product_category id={{selected_catalogs}} limit={{limit}}]</span>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import ProductCatalogService from "@/services/store/ProductCatalogService";
    import WebsiteService from "@/services/Websites/WebsiteService";
    import WebsiteBlogCategoryService from "@/services/Websites/WebsiteBlogCategoryService";
    import WebsiteSettingService from "@/services/Websites/WebsiteSettingService";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import WebsiteCustomFormService from "@/services/Websites/WebsiteCustomFormService";

    const catalogService = new ProductCatalogService();
    const websiteService = new WebsiteService();
    const settingService = new WebsiteSettingService();
    const blogCategoryService = new WebsiteBlogCategoryService();
    const WebsiteCustomForm = new WebsiteCustomFormService();
    export default {
        name: "support",
        components: {
            Treeselect
        },
        data() {
            return {
                type: null,
                sortValueBy: "ORDER_SELECTED",
                valueConsistsOf: "ALL_WITH_INDETERMINATE",
                product_type: null,
                domain_name: null,
                website: {},
                customForms: [],
                limits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                limit: 0,
                websites: [],
              form_id: null,
                catalog_root_id: null,
                product_catalog_id: null,
                category_ids: null,
                catalogs: [],
                categories: [],
                selected_catalogs: null,
                selected_categories: null,
                blog_types: [
                    {title: "Recently Added", value: "blog_recently_added"},
                    {title: "Blog With Category", value: "blog_with_category"}
                ],
                blog_type: null,
                product_types: [
                    {title: "Best Selling", value: "best_seller"},
                    {title: "Featured", value: "feature_product"},
                    {title: "On Sale", value: "on_sale"},
                    {title: "Recently Added", value: "recently_added"},
                    {title: "Recently Sold", value: "recently_sold"},
                    {title: "Product With Category", value: "product_category"},
                    {title: "Product Without Category", value: "product_without_category"}
                ]
            };
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            },
        },
        watch: {
            product_catalog_id: function (val) {
                this.selected_catalogs = null;
                if (val.length > 0) {
                    this.selected_catalogs = val.join(",");
                }
            }, category_ids: function (val) {
                this.selected_categories = null;
                if (val.length > 0) {
                    this.selected_categories = val.join(",");
                }
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Short Code", route: "dashboard"}
            ]);
            this.getWebsites();
        },
        methods: {
            getCustomForm() {
                WebsiteCustomForm.paginate(this.domain_name)
                    .then(res => {
                        this.customForms = res.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.loading = false;
                    });
            },
            getWebsites() {
                websiteService
                    .getAll()
                    .then(response => {
                        this.websites = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },

            performAction() {
                if (this.type == 'form') {
                    this.getCustomForm()
                }
                if (this.blog_type && this.blog_type == 'blog_with_category') {
                    this.getBlogCategory()
                } else {
                    this.getSetting()
                }
            },

            getSetting() {
                settingService
                    ._get(this.domain_name)
                    .then(response => {
                        this.setting = response.data;
                        this.catalog_root_id = this.setting.ecommerce_root_catalog_id;
                        this.getCatalogs(this.catalog_root_id);
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            getCatalogs(id) {
                catalogService.getCatlogByRootId(id).then(res => {
                    this.catalogs = res.data;
                });
            },
            resetDataTypes() {
                this.product_type = null;
                this.blog_type = null;
                this.product_catalog_id = [];
                this.limit = 0;
            }, getBlogCategory() {
                blogCategoryService.paginate(this.domain_name).then(res => {
                    this.categories = res.data.data;
                });
            },
        }
    };
</script>
